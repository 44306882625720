import React, { useState } from 'react';
import { AlertCircle, CheckCircle } from 'lucide-react';

const WaitlistSection = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError('Please enter your email address.');
      return;
    }
    // Here you would typically send the data to your backend
    console.log('Submitted:', { email, phone });
    setSubmitted(true);
    setError('');
  };

  return (
    <section className="bg-[#131B2E] py-20">
      <div className="max-w-xl mx-auto px-4">
        <h2 className="text-4xl font-semibold text-white mb-4 text-center">Be the first to experience NexusAI</h2>
        <p className="text-[#B0B7C3] mb-8 text-center">Join our waitlist to get early access and exclusive updates.</p>
        
        {submitted ? (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
            <div className="flex items-center">
              <CheckCircle className="w-5 h-5 mr-2" />
              <span className="block sm:inline">Thank you for joining our waitlist!</span>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-[#B0B7C3] mb-1">Email (required)</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="w-full bg-[#0A0E17] text-white px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00E5FF]"
                required
              />
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-[#B0B7C3] mb-1">Phone (optional)</label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter your phone number"
                className="w-full bg-[#0A0E17] text-white px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00E5FF]"
              />
            </div>
            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <div className="flex items-center">
                  <AlertCircle className="w-5 h-5 mr-2" />
                  <span className="block sm:inline">{error}</span>
                </div>
              </div>
            )}
            <button 
              type="submit" 
              className="w-full bg-[#00E5FF] text-[#0A0E17] px-6 py-3 rounded-lg font-medium hover:bg-[#00C4E0] transition-colors duration-300"
            >
              Join Waitlist
            </button>
          </form>
        )}
      </div>
    </section>
  );
};

export default WaitlistSection;