import React from 'react';
import { ChevronRight, FileText, Database, Users } from 'lucide-react';
import EnhancedHeroSection from './EnhancedHeroSection';
import DataFlowDiagram from './HowItWorksSection';
import FeaturesSection from './FeaturesSection';
import UseCasesSection from './UseCasesSection';
import WaitListSection from './WaitListSection';
import Header from './Header';
import CRMRAGComparisonAnimation from './CRMRAGComparisonAnimation';

// In your App component
<EnhancedHeroSection />;
// Header Component
<Header />;
// Hero Section Component
<EnhancedHeroSection />;
<CRMRAGComparisonAnimation />;
<DataFlowDiagram />;
<FeaturesSection />;
<UseCasesSection />;
<WaitListSection />;

// Main App Component
const App = () => {
  return (
    <div className="min-h-screen bg-[#0A0E17] text-white">
      <Header />
      <main>
        <EnhancedHeroSection />
        <CRMRAGComparisonAnimation />
        <DataFlowDiagram/>
        <FeaturesSection />
        <UseCasesSection />
        <WaitListSection />
      </main>
    </div>
  );
};

export default App;