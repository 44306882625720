import React from 'react';
import { FileText, Database, Globe, Mail, Image, FileSpreadsheet, } from 'lucide-react';

const FileTypeIcon = ({ type }) => {
  const iconProps = { size: 40, className: "text-[#00E5FF] mb-2" };
  switch (type) {
    case 'HTML': return <Globe {...iconProps} />;
    case 'PDF': return <FileText {...iconProps} />;
    case 'CSV': return <FileSpreadsheet {...iconProps} />;
    case 'PNG': return <Image {...iconProps} />;
    case 'DOCX': return <FileText {...iconProps} />;
    case 'XML': return <FileText {...iconProps} />;
    case 'JSON': return <FileText {...iconProps} />;
    case 'TXT': return <FileText {...iconProps} />;
    default: return <FileText {...iconProps} />;
  }
};

const CrmIcon = ({ name }) => {
  const iconProps = { size: 40, className: "text-[#00E5FF] mb-2" };
  return <Database {...iconProps} />;
};

const UseCasesSection = () => (
  <section className="bg-[#131B2E] py-20">
    <div className="max-w-7xl mx-auto px-4">
      <h2 className="text-4xl font-semibold text-center text-white mb-12">Supported Integrations</h2>
      
      <div className="mb-16">
        <h3 className="text-2xl font-semibold text-white mb-8">File Types</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-6">
          {['HTML', 'PDF', 'CSV', 'PNG', 'DOCX', 'XML', 'JSON', 'TXT', 'EML'].map((fileType) => (
            <div key={fileType} className="bg-[#0A0E17] p-4 rounded-lg text-center">
              <FileTypeIcon type={fileType} />
              <p className="text-white">{fileType}</p>
            </div>
          ))}
        </div>
      </div>
      
      <div>
        <h3 className="text-2xl font-semibold text-white mb-8">CRM &amp; Service Platforms</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-6">
          {['Salesforce', 'HubSpot', 'Microsoft Dynamics', 'Zendesk', 'ServiceNow', 'Oracle CX', 'SAP CRM'].map((crmName) => (
            <div key={crmName} className="bg-[#0A0E17] p-4 rounded-lg text-center">
              <CrmIcon name={crmName} />
              <p className="text-white">{crmName}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default UseCasesSection;