import React from 'react';
import { motion } from 'framer-motion';

const CRMLogo = ({ name, svg, x, y }) => (
  <motion.g
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1 }}
  >
    <svg x={x} y={y} width="40" height="40" viewBox="0 0 50 50">
      {svg}
    </svg>
    <text x={x + 60} y={y + 25} dominantBaseline="middle" fill="#B0B7C3" fontSize="12">
      {name}
    </text>
  </motion.g>
);

const CRMConnectionAnimation = () => {
  const logos = [
    { 
      name: 'Salesforce',
      svg: (
        <path d="M25,0 C38.8071187,0 50,11.1928813 50,25 C50,38.8071187 38.8071187,50 25,50 C11.1928813,50 0,38.8071187 0,25 C0,11.1928813 11.1928813,0 25,0 Z M25,6 C14.5066667,6 6,14.5066667 6,25 C6,35.4933333 14.5066667,44 25,44 C35.4933333,44 44,35.4933333 44,25 C44,14.5066667 35.4933333,6 25,6 Z" fill="#00A1E0"/>
      )
    },
    {
      name: 'Dynamics',
      svg: (
        <path d="M0,0 L50,0 L50,50 L0,50 L0,0 Z M10,10 L40,10 L40,40 L10,40 L10,10 Z" fill="#002050"/>
      )
    },
    {
      name: 'HubSpot',
      svg: (
        <path d="M25,0 C38.8071187,0 50,11.1928813 50,25 C50,38.8071187 38.8071187,50 25,50 C11.1928813,50 0,38.8071187 0,25 C0,11.1928813 11.1928813,0 25,0 Z M20,15 L20,35 L35,25 L20,15 Z" fill="#FF7A59"/>
      )
    }
  ];

  return (
    <div className="h-48 relative">
      <svg width="100%" height="100%" viewBox="0 0 300 200">
        {logos.map((logo, index) => (
          <React.Fragment key={index}>
            <CRMLogo {...logo} x={10} y={10 + (index * 60)} />
            <CRMLogo {...logo} x={190} y={10 + (index * 60)} />
          </React.Fragment>
        ))}

        <motion.line
          x1="50"
          y1="100"
          x2="130"
          y2="100"
          stroke="#00E5FF"
          strokeWidth="2"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse", ease: "easeInOut" }}
        />
        <motion.line
          x1="170"
          y1="100"
          x2="250"
          y2="100"
          stroke="#00E5FF"
          strokeWidth="2"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse", ease: "easeInOut", delay: 0.75 }}
        />

        <motion.circle
          cx="150"
          cy="100"
          r="30"
          fill="#131B2E"
          initial={{ scale: 0 }}
          animate={{ scale: [1, 1.1, 1] }}
          transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
        />
        <motion.text
          x="150"
          y="100"
          textAnchor="middle"
          dominantBaseline="middle"
          fill="#00E5FF"
          fontSize="14"
          fontWeight="bold"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          LOGO
        </motion.text>
      </svg>
    </div>
  );
};

export default CRMConnectionAnimation;