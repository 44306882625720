import React from 'react';
import { motion } from 'framer-motion';
import { Users, UserCheck, CheckCircle } from 'lucide-react';

const AudienceTargetingAnimation = () => {
  const audiences = [
    { icon: Users, label: 'Employees' },
    { icon: UserCheck, label: 'Customers' },
  ];

  const queries = [
    { audience: 'Employee', query: 'How do I reset a user password?' },
    { audience: 'Customer', query: "What's your return policy?" },
    { audience: 'Employee', query: 'Where can I find the sales report template?' },
    { audience: 'Customer', query: 'How do I track my order?' },
  ];

  return (
    <div className="h-64 relative">
      <div className="absolute left-0 top-0 bottom-0 w-1/4 flex flex-col justify-center space-y-8">
        {audiences.map((audience, index) => (
          <motion.div
            key={audience.label}
            className="flex flex-col items-center"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <audience.icon className="text-[#00E5FF]" size={36} />
            <span className="text-white text-xs mt-1">{audience.label}</span>
          </motion.div>
        ))}
      </div>

      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <motion.div
          className="w-20 h-20 bg-[#131B2E] rounded-full flex items-center justify-center"
          animate={{ rotate: [0, 360] }}
          transition={{ duration: 8, repeat: Infinity, ease: "linear" }}
        >
          <span className="text-[#00E5FF] font-bold text-xl">LLM</span>
        </motion.div>
      </div>

      <div className="absolute right-0 top-0 bottom-0 w-1/2 flex flex-col justify-center space-y-4">
        {queries.map((query, index) => (
          <motion.div
            key={index}
            className="flex items-center space-x-2"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <span className="text-white text-xs flex-grow">{query.query}</span>
            <CheckCircle className="text-green-400" size={16} />
          </motion.div>
        ))}
      </div>

      <motion.div
        className="absolute left-1/4 top-1/2 w-1/4 h-0.5 bg-[#00E5FF] transform -translate-y-1/2"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 1, repeat: Infinity, repeatType: "reverse", ease: "easeInOut" }}
      />
      <motion.div
        className="absolute right-1/2 top-1/2 w-1/4 h-0.5 bg-[#00E5FF] transform -translate-y-1/2"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 1, repeat: Infinity, repeatType: "reverse", ease: "easeInOut", delay: 0.5 }}
      />
    </div>
  );
};

export default AudienceTargetingAnimation;