import React from 'react';
import ParseAnythingAnimation from './ParseAnythingAnimation';
import CRMConnectionAnimation from './CRMConnectionAnimation';
import AudienceTargetingAnimation from './AudienceTargetingAnimation';

const FeatureBox = ({ title, description, children }) => (
  <div className="bg-[#131B2E] p-6 rounded-lg flex flex-col h-full">
    <h3 className="text-xl font-medium text-white mb-4">{title}</h3>
    <div className="flex-grow">
      {children}
    </div>
    <p className="text-[#B0B7C3] mt-4">{description}</p>
  </div>
);

const FeaturesSection = () => (
  <section className="bg-[#0A0E17] py-20">
    <div className="max-w-7xl mx-auto px-4">
      <h2 className="text-4xl font-semibold text-center text-white mb-12">What Makes Us Different</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <FeatureBox
          title="Any document type → we parse anything"
          description="We handle various file formats, ensuring comprehensive data integration."
        >
          <ParseAnythingAnimation />
        </FeatureBox>
        <FeatureBox
          title="Connect from and to any CRM"
          description="Seamless integration with your existing CRM systems for efficient data flow."
        >
          <CRMConnectionAnimation />
        </FeatureBox>
        <FeatureBox
          title="Towards any audience"
          description="Using fine-tuned LLMs, we ensure your knowledge is relevant and catered to the right audience, whether employee or customer-facing."
        >
          <AudienceTargetingAnimation />
        </FeatureBox>
      </div>
    </div>
  </section>
);

export default FeaturesSection;