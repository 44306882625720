import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Database, HelpCircle, X, Check } from 'lucide-react';

const AnimationComponent = ({ isImproved }) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (step < 5) {
        setStep(step + 1);
      } else {
        setStep(0);
      }
    }, 800); // Reduced from 2000ms to 800ms for faster animation

    return () => clearTimeout(timer);
  }, [step]);

  const DatabaseIcon = ({ x, y, label, isHighlighted }) => (
    <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
      <motion.circle
        cx={x}
        cy={y}
        r={30}
        fill={isHighlighted ? "#FFD700" : "transparent"}
        initial={{ scale: 0 }}
        animate={{ scale: isHighlighted ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      />
      <Database x={x - 20} y={y - 20} size={40} color={isHighlighted ? "#0A0E17" : "#00E5FF"} />
      <motion.text x={x} y={y + 30} textAnchor="middle" fill="#B0B7C3" fontSize="12">
        {label}
      </motion.text>
    </motion.g>
  );

  const AnimatedLine = ({ start, end, progress }) => (
    <motion.line
      x1={start.x}
      y1={start.y}
      x2={end.x}
      y2={end.y}
      stroke="#00E5FF"
      strokeWidth="2"
      initial={{ pathLength: 0 }}
      animate={{ pathLength: progress }}
      transition={{ duration: 0.3 }}
    />
  );

  const Query = ({ x, y }) => (
    <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
      <HelpCircle x={x - 20} y={y - 20} size={40} color="#FFD700" />
      <motion.text x={x} y={y + 30} textAnchor="middle" fill="#B0B7C3" fontSize="12">
        Query
      </motion.text>
    </motion.g>
  );

  const Response = ({ x, y, isCorrect }) => (
    <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
      {isCorrect ? (
        <Check x={x - 20} y={y - 20} size={40} color="#4CAF50" />
      ) : (
        <X x={x - 20} y={y - 20} size={40} color="#F44336" />
      )}
      <motion.text x={x} y={y + 30} textAnchor="middle" fill="#B0B7C3" fontSize="12">
        {isCorrect ? "Correct" : "Incorrect"}
      </motion.text>
    </motion.g>
  );

  return (
    <svg width="100%" height="100%" viewBox="0 0 400 300">
      <DatabaseIcon x={50} y={150} label="CRM Data" isHighlighted={false} />
      <DatabaseIcon x={125} y={75} label="Knowledge Base" isHighlighted={isImproved} />
      <DatabaseIcon x={125} y={225} label="External Sources" isHighlighted={false} />
      
      {step > 0 && <AnimatedLine start={{x: 60, y: 150}} end={{x: 200, y: 150}} progress={step > 1 ? 1 : 0} />}
      {step > 0 && <AnimatedLine start={{x: 135, y: 75}} end={{x: 200, y: 150}} progress={step > 2 ? 1 : 0} />}
      {step > 0 && <AnimatedLine start={{x: 135, y: 225}} end={{x: 200, y: 150}} progress={step > 3 ? 1 : 0} />}
      
      {step > 0 && (
        <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
          <motion.rect x={190} y={130} width={40} height={40} rx={5} fill="#131B2E" />
          <motion.text x={210} y={155} textAnchor="middle" fill="#00E5FF" fontSize="12" fontWeight="bold">
            {isImproved ? "RAG" : "RAG"}
          </motion.text>
        </motion.g>
      )}
      
      {step > 3 && <AnimatedLine start={{x: 210, y: 150}} end={{x: 275, y: 150}} progress={1} />}
      {step > 3 && <Query x={300} y={150} />}
      
      {step > 4 && (
        <Response x={350} y={150} isCorrect={isImproved} />
      )}
    </svg>
  );
};

const CRMRAGComparisonAnimation = () => {
  return (
    <div className="bg-[#0A0E17] p-8 rounded-lg">
      <h2 className="text-3xl font-bold text-white mb-6 text-center">Transforming CRM Knowledge</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h3 className="text-xl font-semibold text-white mb-4 text-center">Traditional CRM RAG</h3>
          <p className="text-[#B0B7C3] mb-4 text-center">Struggles with complex queries and inconsistent data</p>
          <div className="h-72 bg-[#131B2E] rounded-lg">
            <AnimationComponent isImproved={false} />
          </div>
        </div>
        <div>
          <h3 className="text-xl font-semibold text-white mb-4 text-center">NexusAI Enhanced RAG</h3>
          <p className="text-[#B0B7C3] mb-4 text-center">Seamlessly integrates data for accurate responses</p>
          <div className="h-72 bg-[#131B2E] rounded-lg">
            <AnimationComponent isImproved={true} />
          </div>
        </div>
      </div>
      <div className="mt-8 text-center">
        <p className="text-white text-lg mb-4">Experience the power of NexusAI in your CRM system</p>
        <button className="bg-[#00E5FF] text-[#0A0E17] px-6 py-3 rounded-lg font-medium hover:bg-[#00C4E0] transition-colors duration-300">
          Join the Waitlist
        </button>
      </div>
    </div>
  );
};

export default CRMRAGComparisonAnimation;