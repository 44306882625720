import React from 'react';

const AnimatedBackground = () => (
    <svg
      className="absolute inset-0 w-full h-full"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      <defs>
        <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#00E5FF" stopOpacity="0" />
          <stop offset="50%" stopColor="#00E5FF" stopOpacity="0.2" />
          <stop offset="100%" stopColor="#00E5FF" stopOpacity="0" />
        </linearGradient>
      </defs>
      {[...Array(20)].map((_, i) => (
        <line
          key={i}
          x1={0}
          y1={5 + i * 5}
          x2={100}
          y2={5 + i * 5}
          stroke="url(#lineGradient)"
          strokeWidth="0.2"
          opacity="0.3"
        >
          <animateTransform
            attributeName="transform"
            type="translate"
            values={i % 2 === 0 ? "0,0; 1,0; 0,0" : "0,0; -1,0; 0,0"}
            dur="20s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            values="0.3;0.5;0.3"
            dur={`${10 + i * 0.5}s`}
            repeatCount="indefinite"
          />
        </line>
      ))}
    </svg>
  );

const EnhancedHeroSection = () => (
  <section className="relative bg-[#0A0E17] text-white py-20 overflow-hidden">
    <AnimatedBackground />
    <div className="relative max-w-7xl mx-auto text-center px-4">
      <h1 className="text-5xl md:text-6xl font-bold mb-6 animate-fadeIn">
        Get your CRM Knowledge RAG-ready
      </h1>
      <p className="text-xl md:text-2xl text-[#B0B7C3] mb-8 leading-relaxed max-w-3xl mx-auto animate-fadeIn animation-delay-300">
        Whether it's einstein or any other RAG tool, NexusAI makes your knowledge perfect, so that you are ready for AI
      </p>
      <button className="bg-[#00E5FF] text-[#0A0E17] px-8 py-3 rounded-lg font-medium text-lg transition-transform hover:scale-105 animate-fadeIn animation-delay-600">
        Join Waitlist
      </button>
    </div>
  </section>
);

export default EnhancedHeroSection;