import React from 'react';
import { motion } from 'framer-motion';
import { FileText, Database, Image } from 'lucide-react';

const ParseAnythingAnimation = () => {
  const fileTypes = [
    { icon: FileText, label: 'PDF' },
    { icon: Database, label: 'DB' },
    { icon: Image, label: 'IMG' },
  ];

  return (
    <div className="h-48 relative">
      <div className="absolute inset-0 flex items-center justify-center">
        <motion.div
          className="w-16 h-16 bg-[#131B2E] rounded-full flex items-center justify-center"
          animate={{ scale: [1, 1.1, 1], rotate: [0, 360, 0] }}
          transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
        >
          <span className="text-[#00E5FF] font-bold text-lg">LOGO</span>
        </motion.div>
      </div>
      <div className="absolute inset-0 flex items-center justify-between px-8">
        <div className="flex flex-col space-y-4">
          {fileTypes.map((file, index) => (
            <motion.div
              key={file.label}
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="w-12 h-12 bg-[#131B2E] rounded-lg flex flex-col items-center justify-center">
                <file.icon className="text-[#00E5FF]" size={20} />
                <span className="text-white text-xs mt-1">{file.label}</span>
              </div>
            </motion.div>
          ))}
        </div>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <div className="w-16 h-16 bg-[#131B2E] rounded-lg flex flex-col items-center justify-center">
            <Database className="text-[#00E5FF]" size={24} />
            <span className="text-white text-xs mt-1 text-center">Unified Database</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ParseAnythingAnimation;