import React, { useState } from 'react';
import { X } from 'lucide-react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-[#131B2E] p-6 rounded-lg max-w-md w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-[#B0B7C3] hover:text-white">
            <X size={24} />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const Header = () => {
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);

  return (
    <>
      <header className="sticky top-0 bg-opacity-90 bg-[#0A0E17] p-4 z-10">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <div className="text-white font-bold text-xl">LOGO</div>
          <button 
            className="bg-[#00E5FF] text-[#0A0E17] px-4 py-2 rounded font-medium hover:bg-[#00C4E0] transition-colors duration-300"
            onClick={() => setIsWaitlistOpen(true)}
          >
            Join Waitlist
          </button>
        </div>
      </header>

      <Modal isOpen={isWaitlistOpen} onClose={() => setIsWaitlistOpen(false)}>
        <WaitlistForm onClose={() => setIsWaitlistOpen(false)} />
      </Modal>
    </>
  );
};

const WaitlistForm = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError('Please enter your email address.');
      return;
    }
    // Here you would typically send the data to your backend
    console.log('Submitted:', { email, phone });
    setSubmitted(true);
    setError('');
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold text-white mb-4">Join the NexusAI Waitlist</h2>
      {submitted ? (
        <div className="text-green-400 mb-4">
          Thank you for joining our waitlist! We'll be in touch soon.
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-[#B0B7C3] mb-1">Email (required)</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full bg-[#0A0E17] text-white px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00E5FF]"
              required
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-[#B0B7C3] mb-1">Phone (optional)</label>
            <input
              type="tel"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter your phone number"
              className="w-full bg-[#0A0E17] text-white px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00E5FF]"
            />
          </div>
          {error && <div className="text-red-400 text-sm">{error}</div>}
          <button 
            type="submit" 
            className="w-full bg-[#00E5FF] text-[#0A0E17] px-6 py-3 rounded-lg font-medium hover:bg-[#00C4E0] transition-colors duration-300"
          >
            Join Waitlist
          </button>
        </form>
      )}
    </div>
  );
};

export default Header;