import React from 'react';
import { motion } from 'framer-motion';
import { Database, HelpCircle, Globe, FileText, Mail } from 'lucide-react';

const AnimatedLine = ({ start, end }) => (
  <motion.line
    x1={start.x}
    y1={start.y}
    x2={end.x}
    y2={end.y}
    stroke="#00E5FF"
    strokeWidth="1.5"
    initial={{ pathLength: 0, opacity: 0 }}
    animate={{ pathLength: 1, opacity: 1 }}
    transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse", ease: "easeInOut" }}
  />
);

const IconWrapper = ({ icon: Icon, x, y, label, description, alignment = 'bottom' }) => (
  <g>
    <foreignObject x={x - 40} y={y - 40} width={80} height={80}>
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="w-20 h-20 flex items-center justify-center bg-[#131B2E] rounded-full"
      >
        <Icon size={48} color="#00E5FF" />
      </motion.div>
    </foreignObject>
    <motion.text
      x={alignment === 'left' ? x - 50 : alignment === 'right' ? x + 50 : x}
      y={alignment === 'bottom' ? y + 60 : y}
      fill="#D3D3D3"
      fontSize="16"
      textAnchor={alignment === 'left' ? "end" : alignment === 'right' ? "start" : "middle"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      {label}
    </motion.text>
    {description && (
      <motion.text
        x={alignment === 'left' ? x - 50 : alignment === 'right' ? x + 50 : x}
        y={alignment === 'bottom' ? y + 80 : y + 20}
        fill="#B0B7C3"
        fontSize="12"
        textAnchor={alignment === 'left' ? "end" : alignment === 'right' ? "start" : "middle"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        {description}
      </motion.text>
    )}
  </g>
);

const DataFlowDiagram = () => {
  const spacing = 200;
  const startX = 150;
  const centerY = 300;

  return (
    <section className="bg-[#0A0E17] py-20">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-semibold text-center text-white mb-12">How It Works</h2>
        <p className="text-xl text-center text-[#B0B7C3] mb-12 max-w-3xl mx-auto">
          We connect your knowledge and data into a unified whole. No matter the audience or format, we make sure you are covered and enable your data for a ton of generative AI use cases.
        </p>
        <svg className="w-full h-[600px]" viewBox="0 0 1200 600">
          <IconWrapper
            icon={Database}
            x={startX}
            y={centerY - 150}
            label="Knowledge base"
            description="Employee facing"
            alignment="left"
          />
          <IconWrapper
            icon={Globe}
            x={startX}
            y={centerY - 50}
            label="Website"
            description="Information articles"
            alignment="left"
          />
          <IconWrapper
            icon={FileText}
            x={startX}
            y={centerY + 50}
            label="Guides on Sharepoint"
            description="Long PDFs for customer service"
            alignment="left"
          />
          <IconWrapper
            icon={Mail}
            x={startX}
            y={centerY + 150}
            label="Email templates"
            description="Customer facing templates"
            alignment="left"
          />

          {[0, 1, 2, 3].map(i => (
            <AnimatedLine 
              key={i}
              start={{ x: startX + 40, y: centerY - 150 + i * 100 }} 
              end={{ x: startX + spacing - 40, y: centerY }} 
            />
          ))}

          <motion.rect
            x={startX + spacing - 40}
            y={centerY - 40}
            width={80}
            height={80}
            rx="10"
            fill="#00E5FF"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          />
          <motion.text
            x={startX + spacing}
            y={centerY + 5}
            textAnchor="middle"
            fill="#0A0E17"
            fontSize="16"
            fontWeight="bold"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}
          >
            LOGO
          </motion.text>

          <AnimatedLine start={{ x: startX + spacing + 40, y: centerY }} end={{ x: startX + spacing * 2 - 40, y: centerY }} />

          <IconWrapper
            icon={Database}
            x={startX + spacing * 2}
            y={centerY}
            label="Unified Database"
            description=""
            alignment="bottom"
          />

          <AnimatedLine start={{ x: startX + spacing * 2 + 40, y: centerY }} end={{ x: startX + spacing * 3 - 40, y: centerY }} />

          <IconWrapper
            icon={HelpCircle}
            x={startX + spacing * 3}
            y={centerY}
            label="CRM Systems"
            description=""
            alignment="bottom"
          />

          <AnimatedLine start={{ x: startX + spacing * 3 + 40, y: centerY }} end={{ x: startX + spacing * 4 - 40, y: centerY }} />

          <motion.circle
            cx={startX + spacing * 4}
            cy={centerY}
            r="40"
            fill="#131B2E"
            stroke="#00E5FF"
            strokeWidth="1.5"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 1.5 }}
          />
          <motion.text
            x={startX + spacing * 4}
            y={centerY + 5}
            textAnchor="middle"
            fill="#00E5FF"
            fontSize="16"
            fontWeight="bold"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 2 }}
          >
            LLM
          </motion.text>
          <motion.text
            x={startX + spacing * 4}
            y={centerY + 60}
            textAnchor="middle"
            fill="#D3D3D3"
            fontSize="16"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 2.2 }}
          >
            LLM
          </motion.text>

          <IconWrapper
            icon={HelpCircle}
            x={startX + spacing * 5}
            y={centerY - 100}
            label="User Query 1"
            description=""
            alignment="right"
          />
          <IconWrapper
            icon={HelpCircle}
            x={startX + spacing * 5}
            y={centerY}
            label="User Query 2"
            description=""
            alignment="right"
          />
          <IconWrapper
            icon={HelpCircle}
            x={startX + spacing * 5}
            y={centerY + 100}
            label="User Query 3"
            description=""
            alignment="right"
          />

          <AnimatedLine start={{ x: startX + spacing * 4 + 40, y: centerY }} end={{ x: startX + spacing * 5 - 40, y: centerY - 100 }} />
          <AnimatedLine start={{ x: startX + spacing * 4 + 40, y: centerY }} end={{ x: startX + spacing * 5 - 40, y: centerY }} />
          <AnimatedLine start={{ x: startX + spacing * 4 + 40, y: centerY }} end={{ x: startX + spacing * 5 - 40, y: centerY + 100 }} />
        </svg>
      </div>
    </section>
  );
};

export default DataFlowDiagram;